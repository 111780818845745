import '../App.scss';
import React, { Component } from 'react';
import Footer from './Footer';
import Spinner from './Spinner';
import sanitizeHtml from 'sanitize-html';
import config from '../config';

const pfLogo = '/assets/images/logo.svg';
const stripe = '/assets/images/racing-stripe.svg';
const homepagePhoto = '/assets/images/homepage-img-horizontal.jpg';

class LaunchPatient extends Component {
    state = {
        isLoading: true,
        redirectUrl: null,
        practiceGuid: null,
        applicationName: null,
        accessToken: null,
        patients: []
    }
    componentWillMount() {
        const queryParams = this.getQueryParams();
        const hashParams = this.getHashParams();
        const { auth0Route } = config.launchPatient;

        const defaultState = {
            accessToken: queryParams.accessToken || hashParams.accessToken,
            redirectUrl: decodeURIComponent(`${auth0Route}/continue`),
            practiceGuid: queryParams.practiceGuid || hashParams.practiceGuid,
            applicationName: queryParams.applicationName || hashParams.applicationName,
            authState: queryParams.authState || hashParams.authState,
            selectedPatient: null,
            isLoading: true
        };

        this.setState(defaultState);
    }

    async componentDidMount() {
        const { accessToken, practiceGuid, redirectUrl, authState } = this.state;

        try {
            this.setSpinner(true);
            const patients = await this.loadUserPatients(accessToken, practiceGuid);
            this.setState({ patients });

            if (patients.length === 1) {
                this.continueAuth(redirectUrl, patients[0], authState);
            } else if (patients.length < 1) {
                this.continueAuth(redirectUrl, null, authState);
            } else {
                this.setSpinner(false);
            }
        } catch(err) {
            this.continueAuth(redirectUrl, null, authState);
        }
    }

    getHashParams() {
        let { hash } = window.location;
        const hashParams = {};
        if (hash[0]==='#') {
            hash = hash.substring(1);
        }
        const params = new URLSearchParams(hash.replace('/launchpatient',''));

        if (params.has('access_token')) {
            const decodedToken = decodeURIComponent(params.get('access_token'));
            const sanitizedValue = sanitizeHtml((decodedToken || '').indexOf('Bearer') < 0 ? `Bearer ${decodedToken}` : decodedToken);
            hashParams['accessToken'] = sanitizedValue.replace(/&amp;/g, '&');
        }
        if (params.has('practice_guid')) {
            const decodedGuid = decodeURIComponent(params.get('practice_guid'));
            const sanitizedValue = sanitizeHtml(decodedGuid);
            hashParams['practiceGuid'] = sanitizedValue.replace(/&amp;/g, '&');
        }
        if (params.has('client_application_name')) {
            const decodedAppName= decodeURIComponent(params.get('client_application_name'));
            const sanitizedValue = sanitizeHtml(decodedAppName);
            hashParams['applicationName'] = sanitizedValue.replace(/&amp;/g, '&');
        }
        if (params.has('state')) {
            const decodedState= decodeURIComponent(params.get('state'));
            const sanitizedValue = sanitizeHtml(decodedState);
            hashParams['authState'] = sanitizedValue.replace(/&amp;/g, '&');
        }
        return hashParams;
    }

    getQueryParams() {
        const queryParams = {};
        const { search } = window.location;
        if (search) {
            const params = (search || '').substring(1).split('&');
            for (var i = 0; i < params.length; i++) {
                const [key, value] = params[i].split('=');
                const decodedValue = decodeURIComponent(value);
                let sanitizedValue = sanitizeHtml(decodedValue);
                if (key === 'access_token') {
                    sanitizedValue = (sanitizedValue.indexOf('Bearer') < 0 ? `Bearer ${decodedValue}` : decodedValue);
                    queryParams['accessToken'] = sanitizedValue.replace(/&amp;/g, '&');
                }
                if (key === 'practice_guid') {
                    queryParams['practiceGuid'] = sanitizedValue.replace(/&amp;/g, '&');
                }
                if (key === 'client_application_name') {
                    queryParams['applicationName'] = sanitizedValue.replace(/&amp;/g, '&');
                }
                if (key === 'state') {
                    queryParams['authState'] = sanitizedValue.replace(/&amp;/g, '&');
                }
            }
        }

        return queryParams;
    }

    async loadUserPatients(token, practiceGuid) {
        const { patientEndpoint } = config.launchPatient;
        const requestHeaders = new Headers();
        requestHeaders.append('authorization', token);
        const patientRequest = new Request(
            patientEndpoint,
            {
                method: 'GET',
                headers: requestHeaders
            }
        );

        const response = await fetch(patientRequest);
        const responseJson = await response.json();
        const patients = (responseJson.data || []).map(patient => {
            const { firstName, lastName, patientPractices = [] } = patient;
            const activePractices = patientPractices.filter(patientPractice => (patientPractice.isPpraEnabled)) || [];
            const activeSelectedPractices = practiceGuid ? activePractices.filter(patientPractice => (patientPractice.practiceGuid === practiceGuid)) : activePractices;
            const { patientPracticeGuid } = activeSelectedPractices[0] || {};
            
            return Object.assign(patient, {
                fullName: `${firstName} ${lastName}`,
                patientPracticeGuid
            });
        });
        
        return patients.filter(patient => patient.patientPracticeGuid);
    }

    setSpinner = (isLoading) => {
        this.setState({
            isLoading
        });
    }

    continueAuth = (url, patient, authState) => {
        this.setSpinner(true);
        const paramAppend = (url || '').indexOf('?') > -1 ? '&' : '?';
        const params = [];
        if (patient) {
            params.push(`patientPracticeGuid=${patient.patientPracticeGuid}`);
        }
        if (authState) {
            params.push(`state=${authState}`);
        }
        const validatedUrl = `${url}${paramAppend}${params.join('&')}`;

        if (url && validatedUrl) {
            window.location = validatedUrl;
        } else {
            this.setSpinner(false);
        }
    }

    selectPatient = (patient) => {
        this.setState({
            selectedPatient: patient
        });
    }

    render() {
        const { patients, selectedPatient, redirectUrl, applicationName, authState } = this.state;
        const isNextEnabled = selectedPatient && selectedPatient.patientPracticeGuid;
        const patientCount = (patients || []).length;

        return (
            <div className="flex-column fill-space--relative">
                <div className="pf-header hidden--xs">
                    <div className="pf-logo"><img src={pfLogo} width="190" alt="practice fusion logo" /></div>
                </div>
                <div className="racing-stripe hidden--xs">
                    <div className="racing-stripe__container">
                        <img className="racing-stripe__img" src={stripe} alt="" />
                    </div>
                </div>
                <div className="overflow-auto">
                    <img className="homepage-image__img hidden--xs" alt="homepage" src={homepagePhoto} />
                    <div className={this.state.isLoading ? "launch-patient--hidden" : ""}>
                        <div className="inline-flex-group form-wrapper">
                            <div className="inline-flex-group__item--md hidden--xs">
                                <div className = "launch-patient-instructions">
                                    <h2 className="text-orange padding-Bmd">
                                        Choose which patient records to connect
                                    </h2>
                                    <p>
                                        Your PatientFusion account includes {patientCount} records.
                                    </p>
                                    <p>
                                        Select a patient from the list of patients in the practice.
                                        <br/>
                                        In the next step, you can confirm the information {applicationName || "this application"} will be able to access.
                                    </p>
                                    <p>
                                        You are authorizing the transfer of your personal health record to a third party.  
                                        Once you “login”, Practice Fusion does not have control over, and Practice Fusion is not responsible for, how the information is used or subsequently disclosed.  
                                        You may revoke further access at any time by visiting the Authorized Apps section of the Account Settings in your Patient Fusion account.
                                    </p>
                                </div>
                            </div>
                            <div className='faux-auth0-panel launch-patient__form-container'>
                                <div className="faux-auth0-panel__header">
                                    <img alt="" className="faux-auth0-panel__header-logo" src="/assets/images/icon-pf-logo.svg"/>
                                </div>
                                <div className="faux-auth0-panel__body launch-patient__form-body">
                                    <div className="padding-Blg">Select the patient from the list below</div>
                                    {patients.map((patient, index) => (
                                        <div className="radio-option" key={`scope-${index}-key`}>
                                            <input id={`patient-${index}`} type="radio" checked={selectedPatient === patient} onChange={() => this.selectPatient(patient)}></input>
                                            <label htmlFor={`patient-${index}`} title={patient.fullName}>{patient.fullName}</label>
                                        </div>
                                    ))}
                                </div>
                                <button className="faux-auth0-panel__continue-button continue-button--with-disable-state" name="submit" type="submit" aria-label="Next" disabled={!isNextEnabled} onClick={() => this.continueAuth(redirectUrl, selectedPatient, authState)}>
                                    <span>Next</span>
                                    <span className="padding-Lmd"><svg aria-hidden="true" focusable="false" className="icon-text" width="8px" height="12px" viewBox="0 0 8 12" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="Web/Submit/Active" transform="translate(-148.000000, -32.000000)" fill="#FFFFFF"><polygon id="Shape" points="148 33.4 149.4 32 155.4 38 149.4 44 148 42.6 152.6 38"></polygon></g></g></svg></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <Spinner isLoading={this.state.isLoading} />
            </div>
        );
    }
}

export default LaunchPatient;
