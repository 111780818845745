import React from 'react';
import '../styles/components/multiple-email-error.scss';

const pfLogo = '/assets/images/icon-pf-logo.svg';
const stripe = '/assets/images/racing-stripe.svg';

const MultipleEmailError = () => (
    <div className="homepage-wrapper">
        <div className="blurb-row">
            <div className="pf-logo message-logo"><img src={pfLogo} width="190" alt="practice fusion logo" /></div>
            <div className = "email-error-racing-stripe hidden--xs" >
                <div className = "email-error-acing-stripe__container" >
                    <img className="email-error-racing-stripe__img" src={stripe} alt="" />
                </div>
            </div>
            <div className = "message-content">
                <p className="content-title">
                    Contact your healthcare provider to proceed
                </p>
                <p className="content-font">
                    We have enhanced Patient Fusion's login system and now require a unique email address as the login credential for your account. Our records 
                    show that multiple Patient Fusion accounts exist using the same email address you have entered.
                </p>
                <br/>
                <p className="content-font">
                    Follow the steps below to renew access to your medical records:
                </p>
                <ul className="content-list">
                    <li className="content-font">
                        Contact your healthcare provider and request that they revoke your access, then re-invite you to the portal 
                        using a different email address that is not already registered on Patient Fusion.
                    </li>
                    <li className="content-font">
                        You will get a new invite to join Patient Fusion - follow the steps to sign up for a new account. Remember 
                        you must use an email address not already registered on Patient Fusion.
                    </li>
                    <li className="content-font">
                        After your new account is created, you will be asked to verify your identity to access your medical records.
                    </li>
                    <li className="content-font">
                        If you wish to link multiple patient records to your new account (e.g if you are a caregiver for a child or relative), 
                        you may do so by following the instructions <a className="content-link" href="http://help.patientfusion.com/">here.</a>
                    </li>
                </ul>
            </div>
            
        </div>
    </div>
);

export default MultipleEmailError;