import './App.scss';
import React from 'react';
import { Switch, Route, HashRouter } from "react-router-dom";
import Default from "./components/Default";
import Scopes from "./components/Scopes";
import LaunchPatient from "./components/LaunchPatient";

function App() {
    return (
        <div className="App fill-space--absolute">
            <HashRouter>
                <Switch>
                    <Route path="/scopes">
                        <Scopes/>
                    </Route>
                    <Route path="/launchpatient">
                        <LaunchPatient/>
                    </Route>
                    <Route path="/">
                        <Default/>
                    </Route>
                </Switch>
            </HashRouter>
        </div>
      )
    }
    
    export default App
