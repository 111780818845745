import React from 'react';

const iconAppt = '/assets/images/icon_calendar.png';
const iconLock = '/assets/images/icon_lock.png';
const iconPf = '/assets/images/icon_document.png';

const HomepageBlurbs = () => (
    <React.Fragment>
        <div className="mobile-blurbs margin-Txl padding-LRxl padding-Txl hidden--not-xs">
            <h2 className="text-orange">Access your health records anytime</h2>
            <div className="access-health-record-blurb">
                Connect with your Patient Fusion doctor to see key health information recorded during your visits, including:
                <div className="padding-Tmd padding-Llg">
                    <li> Upcoming appointments </li>
                    <li> Recent lab orders </li>
                    <li> Medications, diagnoses and more</li>
                </div>
            </div>
        </div>
        <div className="mobile-blurbs padding-LRxl padding-TBxl margin-Bxl hidden--not-xs">
            <h2 className="text-orange padding-Bmd">How do I sign up?</h2>
            <div className="inline-flex-group sign-up-blurb__list padding-Blg">
                <div className="sign-up-blurb__list-icon"><img src={iconAppt} alt="appointment" /></div>
                <div className="sign-up-blurb__list-text padding-Lmd">Find a doctor on <a className="color-blue" href="https://www.patientfusion.com">patientfusion.com</a> and book an appointment</div>
            </div>
            <div className="inline-flex-group sign-up-blurb__list padding-Blg">
                <div className="sign-up-blurb__list-icon"><img src={iconPf} alt="pf" /></div>
                <div className="sign-up-blurb__list-text padding-Lmd">Ask your doctor for access to your health records</div>
            </div>
            <div className="inline-flex-group sign-up-blurb__list">
                <div className="sign-up-blurb__list-icon"><img src={iconLock} alt="lock" /></div>
                <div className="sign-up-blurb__list-text padding-Lmd">Log in to your account to see your records anytime</div>
            </div>
        </div>
        <div className="homepage-wrapper hidden--xs">
            <div className="blurb-row inline-flex-group">
                <div className="blurb-row__blurb padding-Alg">
                    <h2 className="text-orange">Access your health records anytime</h2>
                    <div className="access-health-record-blurb">
                        Connect with your Patient Fusion doctor to see key health information recorded during your visits, including:
                        <div className="padding-Tmd padding-Llg">
                            <li> Upcoming appointments </li>
                            <li> Recent lab orders </li>
                            <li> Medications, diagnoses and more</li>
                        </div>
                    </div>
                </div>
                <div className="blurb-row__pipe margin-TBlg" />
                <div className="blurb-row__blurb padding-Alg">
                    <h2 className="text-orange padding-Bmd">How do I sign up?</h2>
                    <div className="inline-flex-group sign-up-blurb__list">
                        <div className="sign-up-blurb__list-icon"><img src={iconAppt} alt="appointment" /></div>
                        <div className="sign-up-blurb__list-text padding-Lmd">Find a doctor on <a className="color-blue" href="https://www.patientfusion.com">patientfusion.com</a> and book an appointment</div>
                    </div>
                    <div className="inline-flex-group sign-up-blurb__list">
                        <div className="sign-up-blurb__list-icon"><img src={iconPf} alt="pf" /></div>
                        <div className="sign-up-blurb__list-text padding-Lmd">Ask your doctor for access to your health records</div>
                    </div>
                    <div className="inline-flex-group sign-up-blurb__list">
                        <div className="sign-up-blurb__list-icon"><img src={iconLock} alt="lock" /></div>
                        <div className="sign-up-blurb__list-text padding-Lmd">Log in to your account to see your records anytime</div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
);

export default HomepageBlurbs;