import React from 'react';
import { withFormik, Form, Field, ErrorMessage } from 'formik';
import config from '../config';
import HomepageBlurbs from '../components/HomepageBlurbs';

const EmailConfirmationView = (props) => {
    const { errors, values, isSubmitting } = props;

    if (!props.auth0State) {
        window.location = '/';
    }

    return (
        <div>
            <div className="inline-flex-group form-wrapper">
                <div className="inline-flex-group__item--md hidden--xs"></div>
                <div className="faux-auth0-wrapper">
                    <div className="faux-auth0-opened-in-frame faux-auth0-with-tabs">
                        <div className="faux-auth0-center">
                            <div>
                                <Form data-element="sign-up-form">
                                    <div className="faux-auth0-panel__header">
                                        <img alt="" className="faux-auth0-panel__header-logo" src="/assets/images/icon-pf-logo.svg" />
                                    </div>
                                    <div>
                                        <div className="faux-auth0-panel__message">
                                            <br />
                                            Confirm the email that is associated with your Patient Fusion account.
                                            <br />
                                            <br />
                                        </div>
                                        <div className="faux-auth0-panel__message">
                                            <div className="faux-auth0-panel__input-container faux-auth0-input-wrap">
                                                <div className="faux-auth0-panel__input-email-icon faux-auth0-input-x">
                                                    <svg
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        width="14px"
                                                        height="14px"
                                                        viewBox="0 0 32 26"
                                                        version="1.1"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g
                                                            id="Page-1"
                                                            stroke="none"
                                                            strokeWidth="1"
                                                            fill="none"
                                                            fillRule="evenodd">
                                                            <g
                                                                id="32px"
                                                                transform="translate(-2155.000000, -2317.000000)"
                                                                fill="#373A39">
                                                                <g
                                                                    id="Group-856"
                                                                    transform="translate(1.000000, 1.000000)">
                                                                    <path
                                                                        id="Fill-419"
                                                                        d="M2184,2339 C2184,2339.55 2183.55,2340 2183,2340 L2157,2340 C2156.45,2340 2156,2339.55 2156,2339 L2156,2319 C2156,2318.45 2156.45,2318 2157,2318 L2183,2318 C2183.55,2318 2184,2318.45 2184,2319 L2184,2339 L2184,2339 Z M2184,2316 L2156,2316 C2154.89,2316 2154,2316.89 2154,2318 L2154,2340 C2154,2341.1 2154.89,2342 2156,2342 L2184,2342 C2185.1,2342 2186,2341.1 2186,2340 L2186,2318 C2186,2316.89 2185.1,2316 2184,2316 L2184,2316 Z M2176,2322 L2180,2322 L2180,2326 L2176,2326 L2176,2322 Z M2174,2328 L2182,2328 L2182,2320 L2174,2320 L2174,2328 Z M2158,2332 L2172,2332 L2172,2330 L2158,2330 L2158,2332 Z M2158,2336 L2172,2336 L2172,2334 L2158,2334 L2158,2336 Z" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <Field
                                                    className='faux-auth0-input'
                                                    name='email'
                                                    placeholder='yours@example.com'
                                                    tabIndex='0'
                                                    type='text'
                                                    maxLength='128'
                                                    data-element='email-text-box'
                                                />
                                            </div>
                                        </div>
                                        <div className="faux-auth0-panel__message input--error">
                                            <ErrorMessage name="auth0State" />
                                        </div>
                                        <div className="faux-auth0-panel__message input--error">
                                            <ErrorMessage name="email" />
                                        </div>
                                        <br/>
                                    </div>
                                    <div>
                                        <button className='faux-auth0-panel__continue-button' type='submit' disabled={isSubmitting} data-element="confirm-email-button">Continue &gt;</button>
                                    </div>
                                    <Field
                                        type="hidden"
                                        name="auth0State"
                                    />
                                </Form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <HomepageBlurbs />
        </div>
    );
};

const EmailConfirmation = withFormik({
    mapPropsToValues: props => ({
        email: props.email || '',
        auth0State: props.auth0State
    }),

    validateOnBlur: true,
    validateOnChange: false,

    handleSubmit: (values, bag) => {
        window.location = `https://${config.auth0.Domain}/continue?state=${values.auth0State}&email=${values.email}`;
    },

    validate: (values, props) => {
        const errors = {};
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let attr;

        for (attr in values) {
            if (!values[attr] || values[attr].trim() === '') {
                errors[attr] = 'The ' + attr + ' value is required.'
            }
        }

        if (!errors.email && !values.email.match(emailRegex)) {
            errors.email = true
            errors['email'] = 'The email format is invalid.'
        }

        return errors;
    },

})(EmailConfirmationView);

export default EmailConfirmation;