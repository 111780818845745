// TODO: Add production configurations
export default {
  auth0: {
    Audience: 'https://my.patientfusion.com',
    ClientId: '5s571lL06q9y3LxN7EaygckjgPHR20nU',
    Domain: 'auth.patientfusion.com',
    Tenant: 'prd-patientfusion.auth0.com'
  },
  defaultApiHost: 'https://api.practicefusion.com',
  defaultRedirectUrl: 'https://my.patientfusion.com',
  supportedDestinations: [
    'https://my.patientfusion.com',
    'https://intake.patientfusion.com'
  ],
  launchPatient: {
    auth0Route: 'https://auth.patientfusion.com',
    patientEndpoint: 'https://phr-patient-endpoint.patientfusion.com/api/v2/phr_patients'
  }
};
