export default {
  auth0: {
    Audience: 'https://qa-my.patientfusion.com',
    ClientId: 'ca1a6lbDBO3MuLOCuuBR20w3ISbWs487',
    Domain: 'qa-auth.patientfusion.com',
    Tenant: 'qa-patientfusion.auth0.com'
  },
  defaultApiHost: 'https://qa-api.practicefusion.com',
  defaultRedirectUrl: 'https://qa-my.patientfusion.com',
  supportedDestinations: [
    'https://qa-my.patientfusion.com',
    'https://qa-intake.patientfusion.com'
  ],
  launchPatient: {
    auth0Route: 'https://qa-patientfusion.auth0.com',
    patientEndpoint: 'https://qa-phr-patient-endpoint.patientfusion.com/api/v2/phr_patients'
  }
};
