export default {
  auth0: {
    Audience: 'https://rc-my.patientfusion.com',
    ClientId: 'E9l0TPuOt53zMokvicbVotF5zJ0eHmxh',
    Domain: 'rc-auth.patientfusion.com',
    Tenant: 'rc-patientfusion.auth0.com'
  },
  defaultApiHost: 'https://rc-api.practicefusion.com',
  defaultRedirectUrl: 'https://rc-my.patientfusion.com',
  supportedDestinations: [
    'https://rc-my.patientfusion.com',
    'https://rc-intake.patientfusion.com'
  ],
  launchPatient: {
    auth0Route: 'https://rc-patientfusion.auth0.com',
    patientEndpoint: 'https://rc-phr-patient-endpoint.patientfusion.com/api/v2/phr_patients'
  }
};
