import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="footer padding-TBmd">
                <div className="footer__text-container inline-flex-group">
                    <div className="footer__text inline-flex-group__item--xl">
                        <a className="footer__text--link-color" target="_blank" href="http://patientfusion.com/legal/terms" rel="noopener noreferrer">Terms</a> |
                        <a className="footer__text--link-color" target="_blank" href="http://patientfusion.com/legal/privacy" rel="noopener noreferrer">Privacy</a> <span className="hidden--xs">|</span>
                        <span className="hidden--xs">© 2020 Practice Fusion, Inc. Patient Fusion® is a service of Practice Fusion</span>
                    </div>
                </div>
                <div className="footer__text-container hidden--not-xs">
                    <span className="footer__text">© 2020 Practice Fusion, Inc. Patient Fusion® is a service of Practice Fusion</span>
                </div>
            </footer>
        );
    }
}

export default Footer;
