import '../App.scss';
import React, { Component } from 'react';
import EmailConfirmation from './EmailConfirmation';
import Footer from './Footer';
import Lock from './Lock';
import Spinner from './Spinner';
import sanitizeHtml from 'sanitize-html';
import MultipleEmailError from './MultipleEmailError';
import CustomAuth0Error from './CustomAuth0Error';
import config from '../config';

const pfLogo = '/assets/images/logo.svg';
const stripe = '/assets/images/racing-stripe.svg';
const homepagePhoto = '/assets/images/homepage-img-horizontal.jpg';

class Default extends Component {
    state = {
        isLoading: false,
        redirectUrl: null,
        errorMessage: null,
        successMessage: null,
        hashState: null
    }
    lockBanner = {
        UserLoggedOut: 'You have been logged out, thank you for using Patient Fusion.',
        EnrollmentComplete: 'This registration is complete. Log in to the account to see the records.',
        SessionExpired: "Your session has expired. Please log in.",
        EnrollmentContinue: "Please login to proceed.",
        EnrollmentNotFound: 'No registration found. Ask your doctor to send you an invitation.'
    }
    screens = {
        EmailConfirmation: 'EmailConfirmation',
        MultipleEmailError: 'MultipleEmailError',
        EnrollmentExpired: 'EnrollmentExpired',
        PasswordResetWarningPage: 'PasswordResetWarningPage'
    }
    componentWillMount() {
        this.validateHashParams();

        const hashParams = this.getHashParams();
        const queryParams = this.getValidatedQueryParams();

        const {
            hashState,
            hashError,
            hashErrorDescription
        } = hashParams;
        const {
            defaultTab,
            error,
            phrSuccessCode,
            redirect = '',
            screen = '',
            state: auth0State,
            success,
        } = queryParams;
        let {
            email,
            message
        } = queryParams;

        if (email) {
            email = decodeURIComponent(email);
        }

        const state = {
            auth0State,
            defaultTab: (defaultTab || '').toLowerCase() === 'signup' ? 'signUp' : 'login',
            email,
            redirectUrl: this.getValidatedRedirectUrl(redirect),
            screen,
            hashState
        };

        if (state.defaultTab === 'signUp') {
            state.successMessage = `If you already have an account, choose<br>'Log In' below.`;
        }

        if (success || phrSuccessCode) {
            state.successMessage = this.lockBanner[success] || this.lockBanner[phrSuccessCode];

            if (success === 'UserLoggedOut') {
                this.deleteCookie('phr_auth_token');
            }
        } else if (error) {
            if (error === 'EnrollmentExpired') {
                state.screen = 'EnrollmentExpired';
            } else {
                state.errorMessage = this.lockBanner[error];
            }
        } else if (hashError) {
          // this if else can get messy if we want to implement
          // errorMessage from hash along with query errorMessage in the future
            if (hashErrorDescription === `We've sent you an email with instructions to reset your password. Please proceed with a reset to log in to your patient portal.`) {
                state.screen = 'PasswordResetWarningPage';
            }
        }

        // currently message will only overwrite the success message
        // if we choose to make the message more dynamic, then this part needs to change
        if (message) {
            message = decodeURIComponent(message);
            if (message === 'You can now login to the application with the new password.') {
                state.successMessage = "You can now login with your new password.";
            }
        }

        this.setState(state);
    }
    deleteCookie(name) {
        document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=patientfusion.com;`;
    }

    getHashParams() {
        let { hash } = window.location;
        const hashParams = {};
        if (hash[0]==='#') {
            hash = hash.substring(1);
        }
        const params = new URLSearchParams(hash);

        if (hash.indexOf('access_token') > -1) {
            if (params.has('state')) {
                hashParams['hashState'] = params.get('state');;
            }
        }
        if (params.has('error')) {
            hashParams['hashError'] = params.get('error');;
        }
        if (params.has('error_description')) {
            hashParams['hashErrorDescription'] = params.get('error_description');;
        }
        return hashParams;
    }

    getValidatedQueryParams() {
        const queryParams = {};
        const { search } = window.location;
        if (search) {
            const params = (search || '').substring(1).split('&');
            for (var i = 0; i < params.length; i++) {
                const [key, value] = params[i].split('=');
                const decodedValue = decodeURIComponent(value);
                let sanitizedValue = sanitizeHtml(decodedValue);
                sanitizedValue = sanitizedValue.replace(/&amp;/g, '&');

                // If params are unsanitary, abort
                if (decodedValue !== sanitizedValue) {
                    window.location = window.location.origin;
                }
                queryParams[key] = value;
            }
        }

        return queryParams;
    }

    extractHostName(redirectUrl) {
        let hostname;

        if (redirectUrl.indexOf('//') > -1) {
            hostname = redirectUrl.split('/')[2];
        } else {
            hostname = redirectUrl.split('/')[0];
        }

        const hostNameParts = hostname.split(':');
        const hasRedirectPotential = hostNameParts.some(part => (part || '').indexOf('@') > -1);

        if (hasRedirectPotential) {
            return '';
        }

        const [beforePort] = hostNameParts;

        // find & remove "?"
        hostname = beforePort.split('?')[0];

        return hostname.toLowerCase();
    }
    getIsValidHostName(url) {
        const validHostNameRegex= /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/;
        const hostname = this.extractHostName(url);
        const endsWithValidHost = hostname.substring(hostname.length - 18, hostname.length) === '.patientfusion.com';
        const { supportedDestinations } = config;
        let isHostSupported = false;

        supportedDestinations.forEach(destinationUrl => {
            if (url.indexOf(destinationUrl) === 0) {
                isHostSupported = true;
            }
        });

        return hostname.match(validHostNameRegex) && endsWithValidHost && isHostSupported;
    }
    getIsValidUrl(url) {
        const validUrlRegex = /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=-]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
        return url.match(validUrlRegex);
    }
    getValidatedRedirectUrl(redirectUrl) {
        try {
            const decodedRedirectUrl = decodeURIComponent(redirectUrl);

            return this.getIsValidUrl(decodedRedirectUrl) && this.getIsValidHostName(decodedRedirectUrl) ? redirectUrl : null;
        } catch {
            return null;
        }
    }
    setSpinner = (isLoading) => {
        this.setState({
            isLoading
        });
    }
    transitionToPhr = (url) => {
        const validatedUrl = this.getValidatedRedirectUrl(url);

        if (validatedUrl) {
            window.location = validatedUrl;
        } else {
            this.setSpinner(false);
        }
    }
    validateHashParams() {
        const { hash } = window.location;
        if (hash) {
            const params = (hash || '').substring(1).split('&');
            for (var i = 0; i < params.length; i++) {
                const [key, value] = params[i].split('=');
                const decodedValue = decodeURIComponent(value);

                // If params are unsanitary, abort
                if (decodedValue !== sanitizeHtml(decodedValue)) {
                    window.location = window.location.origin;
                }
            }
        }
    }
    selectScreen() {
        let screen;
        switch(this.state.screen) {
            case 'MultipleEmailError':
                screen = <MultipleEmailError/> ;
                break;
            case 'EmailConfirmation':
                screen = <EmailConfirmation auth0State={this.state.auth0State}/>;
                break;
            case 'EnrollmentExpired':
                screen = <CustomAuth0Error
                    centerMessage = 'Your invitation has expired'
                    bottomHeader = 'Contact your provider and request that they resend an invitation.'
                    bottomMessage = ''
                />;
                break;
            case 'PasswordResetWarningPage':
                screen = <CustomAuth0Error
                    centerMessage = 'Your password needs to be reset'
                    bottomHeader = {`We've sent you an email to reset your password.`}
                    bottomMessage = 'If you do not receive an email with a reset link, be sure to check your spam folder.'
                />
                break;
            default:
                screen = <Lock
                    defaultTab={this.state.defaultTab}
                    email={this.state.email}
                    errorMessage={this.state.errorMessage}
                    redirectUrl={this.state.redirectUrl}
                    successMessage={this.state.successMessage}
                    onTransitionToPhr={this.transitionToPhr}
                    onSetSpinner={this.setSpinner}
                    hashState={this.state.hashState}
                />;
        }
        return screen;
    }
    render() {
        const screen = this.selectScreen();
        return (
            <div className="flex-column fill-space--relative">
                <div className="pf-header hidden--xs">
                    <div className="pf-logo"><img src={pfLogo} width="190" alt="practice fusion logo" /></div>
                </div>
                <div className="racing-stripe hidden--xs">
                    <div className="racing-stripe__container">
                        <img className="racing-stripe__img" src={stripe} alt="" />
                    </div>
                </div>
                <div className="overflow-auto">
                    <img className="homepage-image__img hidden--xs" alt="homepage" src={homepagePhoto} />
                    {screen}
                </div>
                <Footer />
                <Spinner isLoading={this.state.isLoading} />
            </div>
        );
    }
}

export default Default;
