import React, { Component } from 'react';

class Spinner extends Component {
    render() {
        return (
            <div className={this.props.isLoading ? "spinner-container" : "hidden"}>
                <div className="lds-spinner">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
            </div>
        );
    }
}

export default Spinner;