export default {
  auth0: {
    Audience: 'https://int-my.patientfusion.com',
    ClientId: 'mCNiBIvXe7vFGAubvFC1iK7ao9K4ZAdu',
    Domain: 'int-auth.patientfusion.com',
    Tenant: 'int-patientfusion.auth0.com'
  },
  defaultApiHost: 'https://int-api.practicefusion.com',
  defaultRedirectUrl: 'https://int-my.patientfusion.com',
  supportedDestinations: [
    'https://int-my.patientfusion.com',
    'https://int-intake.patientfusion.com'
  ],
  launchPatient: {
    auth0Route: 'https://int-patientfusion.auth0.com',
    patientEndpoint: 'https://int-phr-patient-endpoint.patientfusion.com/api/v2/phr_patients'
  }
};
