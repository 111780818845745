import React from 'react';
import '../styles/components/customAuth0Error.scss';
import '../styles/components/faux-auth0-panel.scss';
import HomepageBlurbs from './HomepageBlurbs';

const pfLogo = '/assets/images/icon-pf-logo.svg';

const CustomAuth0Error = (props) => (
    <div>
        <div className="inline-flex-group form-wrapper">
            <div className="inline-flex-group__item--md hidden--xs"></div>
            <div className = "faux-auth0-panel" >
                <div className="faux-auth0-panel__header">
                    <img className="faux-auth0-panel__header-logo" src={pfLogo} width="278" alt="Patient Fusion logo" />
                </div>
                <div className="expired-invitation-center-message">{props.centerMessage}</div>
                <div className="expired-invitation-bottom-message">
                    <p className="header-text">{props.bottomHeader}</p>
                    <p>{props.bottomMessage}</p>
                </div>
            </div>
        </div>
        <HomepageBlurbs />
    </div>
    
);

export default CustomAuth0Error;