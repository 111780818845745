export default {
  auth0: {
    Audience: 'https://stg-my.patientfusion.com',
    ClientId: 'lZdeIGINDpvPfQKe026j8KTPPtBPu0z2',
    Domain: 'stg-auth.patientfusion.com',
    Tenant: 'stg-patientfusion.auth0.com'
  },
  defaultApiHost: 'https://stg-api.practicefusion.com',
  defaultRedirectUrl: 'https://stg-my.patientfusion.com',
  supportedDestinations: [
    'https://stg-my.patientfusion.com',
    'https://stg-intake.patientfusion.com'
  ],
  launchPatient: {
    auth0Route: 'https://stg-patientfusion.auth0.com',
    patientEndpoint: 'https://stg-phr-patient-endpoint.patientfusion.com/api/v2/phr_patients'
  }
};
