import React, { Component, useEffect } from 'react';
import Auth0Lock from 'auth0-lock';
import config from '../config';
import HomepageBlurbs from './HomepageBlurbs';

const pfIcon = '/assets/images/icon-pf-logo.svg';

export class Lock extends Component {
    lock = null;
    constructor(props) {
        super(props);
        this.lock = new Auth0Lock(config.auth0.ClientId, config.auth0.Domain, {
            additionalSignUpFields: [{
                name: 'first_name',
                placeholder: 'First name',
                validator: function(text) {
                    const firstName = text.trim()
                    let hintText = ''

                    if (firstName.length === 0) {
                        hintText = 'Field required'
                    } else if (firstName.length > 255) {
                        hintText = 'You have exceeded the maximum character limit of 255'
                    } else if (firstName.match(/[<>\/]/ig)) {
                        hintText = 'You have entered invalid characters'
                    }

                    return {
                        valid: !hintText,
                        hint: hintText
                    }
                }
            }, {
                name: 'last_name',
                placeholder: 'Last name',
                validator: function(text) {
                    const lastName = text.trim()
                    let hintText = ''

                    if (lastName.length === 0) {
                        hintText = 'Field required'
                    } else if (lastName.length > 255) {
                        hintText = 'You have exceeded the maximum character limit of 255'
                    } else if (lastName.match(/[<>\/]/ig)) {
                        hintText = 'You have entered invalid characters'
                    }

                    return {
                        valid: !hintText,
                        hint: hintText
                    }
                }
            }, {
                name: 'phone_number',
                placeholder: 'Phone number',
                validator: function(number) {
                    let hintText = ''
                    if (number.length === 0) {
                        hintText = 'Field required'
                    } else if (!number.match(/^\d{10}$/)) {
                        hintText = 'Phone number must be in XXXXXXXXXX format.'
                    }
                    return {
                        valid: !hintText,
                        hint: hintText
                    }
                }
            }, {
                name: 'phr_last_redirect_url',
                type: 'hidden',
                value: this.props.redirectUrl
            }],
            allowedConnections: ['Patient-Fusion-Users'],
            auth: {
                audience: config.auth0.Audience,
                responseType: 'token',
                params: {
                    phrLastRedirectUrl: this.props.redirectUrl,
                    // If there is no hashState, either return the redirect url from the query param or the default url from config
                    state: this.props.hashState ? this.props.hashState : (this.props.redirectUrl || config.defaultRedirectUrl),
                }
            },
            /* additional configuration needed for custom domains */
            configurationBaseUrl: 'https://cdn.auth0.com',
            overrides: {
              __tenant: config.auth0.Tenant,
              __token_issuer: config.auth0.Domain
            },
            /* end additional configuration needed for custom domains */
            autofocus: true,
            closable: false,
            container: 'user-input',
            defaultADUsernameFromEmailPrefix: false,
            initialScreen: this.props.defaultTab,
            language: 'en',
            languageDictionary: {
                error: {
                    login: {
                        email_verification_required: 'Please verify your email address before logging in.',
                        'lock.fallback': 'There was an issue with login. Please try again later.',
                        'lock.network': 'Network error, please try again later.',
                        too_many_attempts: "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it."
                    }
                },
                success: {
                    signUp: `<div class='pf-rich-text'>
                                <p class='thank-you-header'><b>Thank you for signing up. </b></p>
                                <p class='verify-email'>Check your email for the confirmation we just sent and click on the link to confirm your email address. </p>
                            </div>`
                },
                passwordStrength: {
                    nonEmpty: 'Field required',
                },
                blankErrorHint: 'Field required',
                usernameFormatErrorHint: 'Username must be 5-50 alphanumeric characters and may contain "+", ".", "_" and "-"',
                invalidErrorHint: 'Username must be 5-50 alphanumeric characters and may contain "+", ".", "_" and "-"',
                title: ''
            },
            loginAfterSignUp: false,
            popupOptions: { width: 300, height: 400, left: 200, top: 300 },
            prefill: {
                email: this.props.email
            },
            rememberLastLogin: true,
            theme: {
                logo: pfIcon,
                primaryColor: '#FF7F00'
            },
            usernameStyle: 'username'
        });

        this.onAuthenticated = this.onAuthenticated.bind(this);
        this.onAuthorizationError = this.onAuthorizationError.bind(this);
        this.onAuthenticated();
        this.onAuthorizationError();
    }
    onAuthorizationError() {
        this.lock.on('authorization_error', (error) => {
            const errorMessage = error.description || error.errorDescription;
            const errorCode = error.code;

            if (errorCode === 'custom_update_duplicate_email' || errorMessage === 'custom_update_duplicate_email') {
                window.location = `${window.location.origin}?screen=MultipleEmailError`;
            } else if (errorMessage) {
                this.lock.hide();
                this.lock.show({
                    flashMessage: {
                        type: 'error',
                        text: errorMessage
                    }
                });
            }
        });
    }
    onAuthenticated() {
        this.lock.on('authenticated', ({ accessToken, state }) => {
            this.props.onSetSpinner(true);

            document.cookie = `phr_auth_token=${accessToken};domain=patientfusion.com;secure`;

            // The redirect url is stored in user info from the auth param
            this.lock.getUserInfo(accessToken, (error, profile) => {
                const redirectUrl = profile['https://patientfusion.com/phr_last_redirect_url'];

                this.props.onTransitionToPhr(decodeURIComponent(redirectUrl || state));
            });
        });
    }
    componentDidMount() {
        const lockBanner = this.props.successMessage || this.props.errorMessage
        const lockBannerType = this.props.successMessage ? 'success' : 'error'
        let flashMessage = lockBanner ? {
            flashMessage: {
                type: lockBannerType,
                text: lockBanner
            }
        } : {};


        let cookiesEnabled = this.areCookiesEnabled();

        if (!cookiesEnabled) {
            flashMessage = {
                flashMessage: {
                    type: 'error',
                    text: 'You must enable cookies in your browser to log in'
                }
            }
        };

        // lock will be empty if enrollment is expired
        if (!this.props.enrollmentExpired) {
            this.lock.show(flashMessage);
        }

        /**
         * Click handler for the back button from forgot password screen
         * Checks for the username and password labels and adds them if they are not present
         */
        const backButtonHandler = () => {
            window.requestAnimationFrame(checkForElement);
        };

        /**
         * Click handler for the back button from forgot password screen
         * Checks for the username and password labels and adds them if they are not present
         */
        const signInHandler = () => {
            window.requestAnimationFrame(checkForLoading);
        };

        /**
         * Click handler for the link to forgot password screen
         * Checks for the email label and adds it if it is not present
         */
        const forgotPasswordLinkHandler = () => {
            window.requestAnimationFrame(checkForEmail);
        };

        /**
         * Click handler for the 'Log in' and 'Sign up' tabs
         * Checks for the username and password labels and adds them if they are not present
         */
        const tabHandler = () => {
            window.requestAnimationFrame(checkForElement);
        };

        // Use requestAnimationFrame to check for the email after a brief delay to ensure they are rendered
        const checkForEmail = () => {
            const emailContainer = document.querySelector('.auth0-lock-input-email');
            const labelForEmail = document.querySelector('label[for="email"]');
            const backButton = document.querySelector('.auth0-lock-back-button');
            if (backButton && emailContainer && !labelForEmail) {
                const emailLabel = document.createElement('label');
                emailLabel.innerHTML = 'Email';
                emailLabel.setAttribute('for', 'email');
                emailContainer.insertBefore(emailLabel, emailContainer.firstChild);
                backButton.addEventListener('click', backButtonHandler);
            } else {
                window.requestAnimationFrame(checkForEmail);
            }
        };

        const checkForLoading = () => {
            const authLock = document.querySelector('.auth0-lock');
            const isLoading = authLock.classList.contains('auth0-lock-mode-loading');
            if (isLoading) {
                window.requestAnimationFrame(checkForLoading);
            } else {
                window.requestAnimationFrame(checkForElement);
            }
        };


        // Use requestAnimationFrame to check for the elements after a brief delay to ensure they are rendered
        const checkForElement = () => {
            const tabs = document.querySelector('.auth0-lock-tabs');
            const alternativeLink = document.querySelector('.auth0-lock-alternative-link');
            const usernameContainer = document.querySelector('.auth0-lock-input-username');
            const paswordContainer = document.querySelector('.auth0-lock-input-show-password');
            const emailContainer = document.querySelector('.auth0-lock-input-email');
            const firstNameContainer = document.querySelector('.auth0-lock-input-first_name');
            const lastNameContainer = document.querySelector('.auth0-lock-input-last_name');
            const phoneNumberContainer = document.querySelector('.auth0-lock-input-phone_number');
            const signInButton = document.querySelector('.auth0-lock-submit');

            const labelForUsername = document.querySelector('label[for="username"]');
            const labelForPassword = document.querySelector('label[for="password"]');
            const labelForEmail = document.querySelector('label[for="email"]');
            const labelForFirstName = document.querySelector('label[for="first_name"]');
            const labelForLastName = document.querySelector('label[for="last_name"]');
            const labelForPhoneNumber = document.querySelector('label[for="phone_number"]');

            if (alternativeLink) {
                alternativeLink.addEventListener('click', forgotPasswordLinkHandler);
            }

            if (signInButton) {
                signInButton.addEventListener('click', signInHandler);
            }

            if (tabs && usernameContainer && paswordContainer) {
                if (!labelForUsername) {
                    const usernameLabel = document.createElement('label');
                    usernameLabel.innerHTML = 'Username';
                    usernameLabel.setAttribute('for', 'username');
                    usernameContainer.insertBefore(usernameLabel, usernameContainer.firstChild);
                }

                if (!labelForPassword) {
                    const passwordLabel = document.createElement('label');
                    passwordLabel.innerHTML = 'Password';
                    passwordLabel.setAttribute('for', 'password');
                    paswordContainer.insertBefore(passwordLabel, paswordContainer.firstChild);
                    tabs.addEventListener('click', tabHandler);
                }

                if (emailContainer && firstNameContainer && lastNameContainer && phoneNumberContainer) {
                    if (!labelForEmail) {
                        const emailLabel = document.createElement('label');
                        emailLabel.innerHTML = 'Email';
                        emailLabel.setAttribute('for', 'email');
                        emailContainer.insertBefore(emailLabel, emailContainer.firstChild);
                    }

                    if (!labelForFirstName) {
                        const firstNameLabel = document.createElement('label');
                        firstNameLabel.innerHTML = 'First name';
                        firstNameLabel.setAttribute('for', 'first_name');
                        firstNameContainer.insertBefore(firstNameLabel, firstNameContainer.firstChild);
                    }

                    if (!labelForLastName) {
                        const lastNameLabel = document.createElement('label');
                        lastNameLabel.innerHTML = 'Last name';
                        lastNameLabel.setAttribute('for', 'last_name');
                        lastNameContainer.insertBefore(lastNameLabel, lastNameContainer.firstChild);
                    }

                    if (!labelForPhoneNumber) {
                        const phoneNumberLabel = document.createElement('label');
                        phoneNumberLabel.innerHTML = 'Phone number';
                        phoneNumberLabel.setAttribute('for', 'phone_number');
                        phoneNumberContainer.insertBefore(phoneNumberLabel, phoneNumberContainer.firstChild);
                    }
                }
            } else {
                window.requestAnimationFrame(checkForElement);
            }
        };
  
      window.requestAnimationFrame(checkForElement);
    }

    areCookiesEnabled() {
        // Create cookie
        document.cookie = "cookietest";
        const areCookiesEnabled = document.cookie.indexOf("cookietest") !== -1;
        // Delete cookie
        document.cookie = "cookietest; expires=Thu, 01-Jan-1970 00:00:01 GMT";
        return areCookiesEnabled;
    }

    render() {
        return (
            <div>
                <div className="inline-flex-group form-wrapper">
                    <div className="inline-flex-group__item--md hidden--xs"></div>
                    <div className='auth0-lock-wrapper'><div id='user-input'></div></div>
                </div>
                <HomepageBlurbs />
            </div>
        );
    }
}

export default Lock;
