import configINT from './configs/INT';
import configQA from './configs/QA';
import configRC from './configs/RC';
import configSTG from './configs/STG';
import configPROD from './configs/PROD';

let config;

switch (window.location.hostname.split('-')[0]) {
    case 'int':
        config = configINT;
        break;
    case 'qa':
        config = configQA;
        break;
    case 'rc':
        config = configRC;
        break;
    case 'stg':
        config = configSTG;
        break;
    // When in production, there won't be an <env>-login.patientfusion.com prefix
    case 'login.patientfusion.com':
        config = configPROD;
        break;
    default:
        config = configINT;
        break;
}

export default {
    ...config
};
