import '../App.scss';
import React, { Component } from 'react';
import Footer from './Footer';
import Spinner from './Spinner';
import sanitizeHtml from 'sanitize-html';
import config from '../config';

const pfLogo = '/assets/images/logo.svg';
const stripe = '/assets/images/racing-stripe.svg';
const _scopesMap = [
    { id: 'openid', description: `Read patient's profile information` },
    { id: 'fhirUser', description: `Read patient's profile information in HL7 FHIR format` },
    { id: 'offline_access', description: `Keep you logged in (potentially including after you close the app)` },
    { id: 'launch/patient', description: `Request that you select a patient (if you can access multiple patients in Patient Fusion)` },
    { id: 'patient/AllergyIntolerance.read', description: `Read information about patient's allergies` },
    { id: 'patient/CarePlan.read', description: `Read care plan notes for patient` },
    { id: 'patient/CareTeam.read', description: `Read information about patient's care team` },
    { id: 'patient/Condition.read', description: `Read information about patient's diagnoses` },
    { id: 'patient/Device.read', description: `Read information about patient's medical devices` },
    { id: 'patient/DiagnosticReport.read', description: `Read patient's lab and imaging results` },
    { id: 'patient/DocumentReference.read', description: `Read documents and clinical notes for patient` },
    { id: 'patient/Encounter.read', description: `Read information about patient's visits with your provider` },
    { id: 'patient/Goal.read', description: `Read patient's health goals` },
    { id: 'patient/Immunization.read', description: `Read patient's immunization history` },
    { id: 'patient/Location.read', description: `Read information about your provider's location` },
    { id: 'patient/MedicationRequest.read', description: `Read information about patient's medications` },
    { id: 'patient/Observation.read', description: `Read information about patient's lab results, vital signs, and tobacco use` },
    { id: 'patient/Organization.read', description: `Read information about your provider's practice` },
    { id: 'patient/Patient.read', description: `Read patient's demographic information` },
    { id: 'patient/Practitioner.read', description: `Read information about your provider` },
    { id: 'patient/Procedure.read', description: `Read information about patient's medical procedures` },
    { id: 'patient/Provenance.read', description: `Read information about which providers have recorded the patient's health data` }
];


/**
 * @typedef {{description: string}} scopeObj
 */
/**
 * @typedef {Object.<string, scopeObject>} scopeDictionary
 */

class Scopes extends Component {
    state = {
        isLoading: true,
        redirectUrl: null,
        scopes: []
    }
    componentWillMount() {
        const queryParams = this.getHashParams();
        const { authorizeUri = '', scope = '' } = queryParams;

        const state = {
            redirectUrl: decodeURIComponent(authorizeUri),
            requestedScopes: (decodeURIComponent(scope) || '').split(' ') || [],
            isAllScopesSelected: true
        };

        this.setState(state);
    }

    async componentDidMount() {
        const response = await fetch(`${config.defaultApiHost}/oauth/management/v1/scopes`);
        const data = await response.json();

        /** @type scopeDictionary */
        const scopeMap = data.scopeDescriptions.patient || {};

        /** @typedef {{display: string, isSelected: boolean}} scopeData */

        this.setState(state => {
            /** @type {scopeObj & scopeData } */
            const scopes = state.requestedScopes.map(scope => ({
                display: scope,
                description: this.getScopeDescription(scope, scopeMap),
                isSelected: true
            }));
            return { scopeMap, scopes, isLoading: false }
        });
    }


    /**
     * @param {string} scope
     * @param {scopeDictionary} scopeMap
     * @returns string
     */
    getScopeDescription(scope, scopeMap) {
        let scopeObj = scopeMap[scope] || _scopesMap.find(s => s.id === scope) || { description: scope };
        return scopeObj.description;
    }

    getHashParams() {
        let { hash } = window.location;
        const hashParams = {};
        if (hash[0]==='#') {
            hash = hash.substring(1);
        }
        const params = new URLSearchParams(hash.replace('/scopes',''));

        if (params.has('scope')) {
            const decodedScope = decodeURIComponent(params.get('scope'));
            let sanitizedValue = sanitizeHtml(decodedScope);
            sanitizedValue = sanitizedValue.replace(/&amp;/g, '&');
            hashParams['scope'] = sanitizedValue;
        }
        if (params.has('authorizeUri')) {
            const decodedAuthUri = decodeURIComponent(params.get('authorizeUri'));
            let sanitizedValue = sanitizeHtml(decodedAuthUri);
            sanitizedValue = sanitizedValue.replace(/&amp;/g, '&');
            hashParams['authorizeUri'] = sanitizedValue;
        }
        return hashParams;
    }

    setSpinner = (isLoading) => {
        this.setState({
            isLoading
        });
    }

    transitionToUrl = (url, scopes) => {
        this.setSpinner(!this.state.isLoading);
        const selectedScopeList = (scopes || []).filter(scope => scope.isSelected).map(scope => scope.display).join(' ');
        const scopeAppend = (url || '').indexOf('?') > -1 ? '&' : '?';
        const validatedUrl = `${url}${scopeAppend}scope=${encodeURIComponent(selectedScopeList)}&scopesConfirmed=true`;

        if (validatedUrl) {
            window.location = validatedUrl;
        } else {
            this.setSpinner(false);
        }
    }

    toggleScope = (scope) => {
        scope.isSelected = !scope.isSelected;
        const allScopes = this.state.scopes || [];
        const isAllSelected = allScopes.filter(scope => scope.isSelected).length === allScopes.length;
        this.setState({
            scopes: this.state.scopes,
            isAllScopesSelected: isAllSelected
        });
    }

    toggleAll = () => {
        const isSelecting = !this.state.isAllScopesSelected;
        (this.state.scopes || []).forEach(scope => {
            scope.isSelected = isSelecting
        })
        this.setState({
            scopes: this.state.scopes,
            isAllScopesSelected: isSelecting
        });
    };

    render() {
        const { scopes, redirectUrl, isLoading, isAllScopesSelected } = this.state;
        const toggleAllDisplay = isAllScopesSelected ? 'Deselect All' : 'Select All';

        return (
            <div className="flex-column fill-space--relative">
                <div className="pf-header hidden--xs">
                    <div className="pf-logo"><img src={pfLogo} width="190" alt="practice fusion logo" /></div>
                </div>
                <div className="racing-stripe hidden--xs">
                    <div className="racing-stripe__container">
                        <img className="racing-stripe__img" src={stripe} alt="" />
                    </div>
                </div>
                <div className="overflow-auto padding-Alg scope-selection">
                    <h3>This application would like access to your information</h3>
                    <div className="disclaimer">
                        Checking the boxes below and clicking “Allow” will grant access to the selected information.
                        &nbsp;<b>Only click “Allow” for applications you trust to handle this information.</b>&nbsp;
                        Clicking “Allow” authorizes the transfer of Protected Health Information to a third party, and Practice Fusion is not responsible for how the information is used or subsequently disclosed.
                        Please note that denying access to requested information may prevent the application from functioning properly or from utilizing complete information.
                    </div>
                    {!isLoading &&
                        <>
                            <button className="btn--link margin-Tmd" onClick={() => this.toggleAll()}>{toggleAllDisplay}</button>
                            <div className="scopes-list">
                                {scopes.map((scope, index) => (
                                    <div className="check-box" key={`scope-${index}-key`}>
                                        <input id={`scope-${index}`} type="checkbox" checked={scope.isSelected} onChange={() => this.toggleScope(scope)}></input>
                                        <label htmlFor={`scope-${index}`} title={scope.description}>
                                            <div>{scope.description || scope.display}</div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <button className="btn--primary margin-Tlg" type="button" onClick={() => this.transitionToUrl(redirectUrl, scopes)}>Allow</button>
                        </>
                    }
                </div>
                <Footer />
                <Spinner isLoading={isLoading} />
            </div>
        );
    }
}

export default Scopes;
